<template>
  <div class="page">
    <Navbar id="navbar" mode="solid" />
    <main class="page-main">
      <div class="page-section" v-if="!showEmptyState">
        <h1 id="page-title" class="section-title" v-t="'page.reviews.title'"/>
        <RatingSummary v-if="!loading" :rating="rating" :reviews="reviews.length" class="mb-8" />
        <div class="skeleton-loader review-container" v-if="loading">
          <SkeletonReview />
          <SkeletonReview />
          <SkeletonReview />
          <SkeletonReview />
        </div>
        <div class="review-container" v-if="!loading">
          <ReviewBox class="review-box" v-for="(review, index) in reviews" :key="index" :img="
            review.image !== ''
              ? `${app_file_base_path}${review.image}`
              : review.image
          " 
          :rating="parseInt(review.rating)" :user-name="review.name" :date="review.updated_at"
          :destination="review.destination" :feedback="review.review" :trim-feedback="false" :show-expander="false" />
        </div>
      </div>
      <div class="page-section" v-else>
        <h1 id="page-title" class="section-title" v-t="'page.reviews.title'"/>
        <EmptyState icon-class="bx bxs-star" :title="$t('empty_state.no_reviews_yet.title')" :text="$t('empty_state.no_reviews_yet_general.text')" />
      </div>
    </main>

    <Footer class="footer" :show-divider="false" />
    <BottomBar class="layout-bar" />
  </div>
</template>
<script>
import Footer from "@/components/layout/Footer";
import Navbar from "@/components/layout/Navbar";
import BottomBar from "@/components/layout/BottomBar";
import ReviewBox from "@/components/review/ReviewBox";
import RatingSummary from "@/components/review/RatingSummary.vue";
import api from "@/api";
import { mapActions } from "vuex";
import EmptyState from "@/components/EmptyState.vue";
import SkeletonReview from "@/components/skeletons/SkeletonReview.vue";

export default {
  name: "Home",
  components: {
    BottomBar,
    Footer,
    Navbar,
    ReviewBox,
    RatingSummary,
    EmptyState,
    SkeletonReview
  },
  data() {
    return {
      mode: "transparent",
      isSearchSheet: false,
      sheetPosition: "sheet-hidden",
      reviews: [],
      rating: undefined,
      loading: true,
      showEmptyState: undefined,
    };
  },
  methods: {
    ...mapActions({
      recordPageView: "tracker/recordPageView",
    }),
    elemViewportReset() {
      //Reset sheet position
      if (window.innerWidth >= 680) {
        this.sheetPosition = "sheet-hidden";
      }
    },
    sortByDateAsc(a, b) {
      a = new Date(a.updated_at);
      b = new Date(b.updated_at);
      return a > b ? -1 : a < b ? 1 : 0;
    },
    getRating(reviews) {
      let amountOfReviews = reviews.length;
      let sum = reviews.reduce(
        (accumulator, review) => accumulator + parseInt(review.rating),
        0
      );
      let average = sum / amountOfReviews;
      average = Math.round(average * 10) / 10;
      return parseFloat(average);
    },
    toggleSearchSheet() {
      if (window.innerWidth <= 1024)
        if (this.sheetPosition === "sheet-hidden") {
          document.body.style.overflowY = "hidden";
          this.sheetPosition = "sheet-reveal";
        } else {
          document.body.style.overflowY = "";
          this.sheetPosition = "sheet-hidden";
        }
    },
    toggleOverflow() {
      if (document.body.style.overflowY === "hidden")
        document.body.style.overflowY = "";
    },
  },
  computed: {
    app_file_base_path() {
      return process.env.VUE_APP_FILE_BASE_PATH;
    },
    distinction() {
      if (this.rating >= 4.25) return "Excellent";
      if (this.rating >= 3.75 && this.rating < 4.25) return "Good";
      if (this.rating >= 3.25 && this.rating < 3.75) return "Average";
      if (this.rating >= 2.75 && this.rating < 3.25) return "Poor";
      if (this.rating >= 2.25 && this.rating < 2.75) return "Bad";
      if (this.rating >= 1 && this.rating < 2.25) return "Miserable";
      return "Miserable";
    },
  },
  async created() {
    this.reviews = [
      ...(await api
        .getReviews()
        .then((res) => {
          this.loading = false;

          if (res.data.reviews !== undefined) {
            this.rating = this.getRating(res.data.reviews);
            return res.data.reviews.sort(this.sortByDateAsc);
          } else {
            this.showEmptyState = true;
          }
        })
        .catch((err) => console.log(err))),
    ];
  },
};
</script>
<style scoped>
.review-container {
  @apply grid sm:grid-cols-2 gap-4;
}

.sheet-hidden {
  @apply opacity-0 transform scale-75 z-0;
}

.sheet-reveal {
  @apply opacity-100 transform scale-100 z-50;
}

.page-main {
  @apply w-full px-4 md:px-12 py-12 bg-yellow-lighter;
}

.page-section {
  @apply w-full max-w-screen-md;
  @apply relative transform -translate-x-1/2 left-1/2;
  @apply mt-28;
}

.grid-table {
  @apply grid grid-cols-1 sm:grid-cols-2 gap-12;
}

.section-title {
  @apply font-semibold text-3xl text-black-base max-w-screen-xl w-full;
  @apply left-1/2 relative transform -translate-x-1/2 mb-14;
}

.layout-bar {
  @apply fixed bottom-0 w-full md:hidden;
}

#navbar {
  @apply fixed top-0 z-20 left-0;
}

.footer {
  @apply pb-20 sm:pb-0;
}

.review-box{
  
}
</style>