<template>
    <div role="status" class="skeleton">
        <div class="skeleton-head-row">
            <div class="skeleton-avatar"></div>
            <div class="skeleton-rating"></div>
        </div>
        <div class="skeleton-details-container">
            <div class="skeleton-details md"></div>
            <div class="skeleton-details sm"></div>
            <div class="skeleton-details lg"></div>
            <div class="skeleton-details lg"></div>
        </div>
    </div>
</template>
<script>
export default {
    name: "SkeletonReview"
}
</script>
<style scoped>
.skeleton {
    @apply animate-pulse flex flex-col gap-y-4 flex-wrap;
    @apply border border-grey-base rounded-lg;
    @apply px-6 py-8;
    @apply inline-block bg-white;
}

.skeleton-head-row {
    @apply flex gap-3 items-center w-full;
}

.skeleton-avatar {
    @apply bg-grey-base h-2 rounded-full;
    @apply h-11 w-11;
}

.skeleton-rating {
    @apply h-8 w-44 bg-grey-base;
}

.skeleton-details-container {
    @apply flex flex-col gap-4 mt-3;
}

.skeleton-details {
    @apply h-2 bg-grey-base rounded-full;
    @apply w-52;
}

.sm {
    @apply w-12;
}


.md {
    @apply w-24;
}

.lg {
    @apply w-full;
}
</style>